import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Copy } from 'react-bootstrap-icons'; // Make sure to install react-bootstrap-icons or use another icon library

import { Toast, ToastContainer } from 'react-bootstrap';
import { ButtonGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';
import { crudGetSubscriptionPlanByTelegramId, crudGetReferralPlanByTelegramId, crudPostReferralPlan } from '../../actions/interactions';
import { ReferralCard } from '../../elements/ReferralCard';



const IconContainer = styled.div`
  position: absolute;
  top: 10px; // Adjust the positioning to fit your design
  right: 10px; // Adjust the positioning to fit your design
  font-size: 1.2em; // Adjust font size as per your needs
`;

const Referral = ({userData}) => {
  const [referralPlanData, setReferralPlanData] = useState({});
  const [subscriptionPlanData, setSubscriptionPlanData] = useState({});
  const [showToast, setShowToast] = useState(false); // State to manage toast visibility

  const textToCopy = referralPlanData ? `Дарю тебе скидку на подписку в YoStory (https://t.me/yostory_bot) 🔥 Чтобы использовать скидку, просто укажи этот код при оформлении подписки 👇🏻 ${btoa(referralPlanData.referral_code)}` : "Placeholder Referral Link Text";
  const userTelegramId = userData.id
  const userUsername = userData.userUsername

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
            // Display the toast notification
            setShowToast(true);
            // Hide the toast notification after 5 seconds
            setTimeout(() => setShowToast(false), 5000);
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
    } else {
      alert(textToCopy)
        // Clipboard API is not supported
    }
  };
  const createNewReferralPlan = () => {
    const newReferralPlanData = {
      "referrer_plan_id": subscriptionPlanData ? subscriptionPlanData.id : null,
      "referral_tier": 1,
      "applied_discount_percent": 5
    }
    crudPostReferralPlan(newReferralPlanData).then(crudReferralPlanData => {
      setReferralPlanData(crudReferralPlanData);
    });
  };
  useEffect(() => {
    crudGetSubscriptionPlanByTelegramId(userTelegramId).then(crudSubscriptionPlanData => {
      setSubscriptionPlanData(crudSubscriptionPlanData);
    });
    crudGetReferralPlanByTelegramId(userTelegramId).then(crudReferralPlanData => {
      setReferralPlanData(crudReferralPlanData);
    });
  }, []);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
          <Toast show={showToast} onClose={() => setShowToast(false)}>
              <Toast.Header>
                  <strong className="me-auto">Уведомление</strong>
                  <small>Только что</small>
              </Toast.Header>
              <Toast.Body>Реферальный код скопирован!</Toast.Body>
          </Toast>
      </ToastContainer>
      {(referralPlanData && subscriptionPlanData && referralPlanData.referral_code) ? 
        <Stack gap={2} className="col-md-5 mx-auto" style={{ display: 'flex', direction: 'column'}}>
          <center>
            <TopHeader>Реферальная программа</TopHeader>
          </center>
          <ButtonGradient onClick={copyToClipboard} variant="primary" style={{ position: 'relative' }}> 
              <div style={{ 
              fontSize: '1.2em',
              fontWeight: 'bold',
              // width: '90%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              paddingLeft: '3em',
              paddingRight: '3em',
              display: 'inline-block'}}>
                {btoa(referralPlanData.referral_code)}
                {/* <Copy size={20}/> */}
              </div>
              <Copy 
                  size={'1.5em'}
                  style={{ 
                      position: 'absolute', 
                      top: '1em', // Adjust this value as needed
                      right: '1em' // Adjust this value as needed
                  }} 
              />
          </ButtonGradient>
          <div style={{width: '100%'}}>
            <center>
              <SectionHeader style={{paddingTop: "1em", paddingBottom: "1em"}}>Скопируйте и перешлите реферальный код!</SectionHeader>
            </center>
            {referralPlanData.referral_bonus_type == 1 ?
              <div>
                <ParagraphText>За каждого друга, купившего подписку по вашему коду, вам начисляются сказки</ParagraphText>
                <ParagraphText><strong>1 сказка</strong> - за друга с базовой подпиской 😊</ParagraphText>
                <ParagraphText><strong>3 сказки</strong> - за друга с продвинутой подпиской😎</ParagraphText>
                <ParagraphText>Ваш друг при этом получает скидку на любую подписку!</ParagraphText>
              </div>
              :
              <div>
                <ParagraphText>За каждого друга, купившего подписку по вашему коду, вам начисляются бонусы🥳</ParagraphText>
                <ParagraphText><strong>25 бонусов</strong> - за друга с базовой подпиской 😊</ParagraphText>
                <ParagraphText><strong>50 бонусов</strong> - за друга с продвинутой подпиской😎</ParagraphText>
                <ParagraphText>Ваш друг при этом получает скидку на любую подписку!</ParagraphText>
              </div>
            }
            
          </div>
          <ReferralCard referralPlan={referralPlanData} subscriptionPlan={subscriptionPlanData} handleCopyToClipboard={copyToClipboard} />
        </Stack>
        :
        <Stack gap={2} className="col-md-5 mx-auto justify-content-between" style={{ height: '80vh', display: 'flex', direction: 'column', justifyContent: 'space-between'}}>
          <center>
            <TopHeader>Реферальная программа</TopHeader>
          </center>
          <div style={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <SectionHeader>Ваш персональный реферальный код</SectionHeader>
            <ButtonGradient variant="primary" onClick={createNewReferralPlan}><div>Создать код</div></ButtonGradient>
            {/* <ButtonGradient variant="primary"><div style={{ fontSize: '0.7em'}}>https://t.me/yostory_bot=185945198</div></ButtonGradient> */}
            <ParagraphTextShort style={{paddingTop: '1em'}}>
                Отправляйте реферальный код друзьям, и получайте бонусы от YoStory за каждого друга, оформившего с ним подписку!
            </ParagraphTextShort>
          </div>
        </Stack>
      }

        {/* <div style={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <SectionHeader>Ваш персональный реферальный код</SectionHeader>
            {
              (referralPlanData && referralPlanData.referral_code) ? <ButtonGradient variant="primary" onClick={copyToClipboard} style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}>
              <div style={{ 
                fontSize: '0.7em',
                width: '90%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
              }}>{referralPlanData.referral_code}</div></ButtonGradient>
              : <ButtonGradient variant="primary" onClick={createNewReferralPlan}><div>Создать код</div></ButtonGradient>
            }
            <ParagraphTextShort style={{paddingTop: '1em'}}>
                Отправляйте реферальный код друзьям, и получайте бонусы от YoStory за каждого друга, оформившего с ним подписку!
            </ParagraphTextShort>
        </div> */}
    </>
  );
}

export default Referral;