import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Toast, ToastContainer } from 'react-bootstrap';

import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';
import { ButtonGradient } from '../../elements/Buttons';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { Copy } from 'react-bootstrap-icons';

import SupportHelpImage from "../../icons/support-help-arrow.png"

// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`

const Support = () => {
  const [showToast, setShowToast] = useState(false); // State to manage toast visibility

  const textToCopy = "Хочу вывести деньги💰"
  const navigate = useNavigate(); 

  const handleCopyAndNavigate = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy)
    } else {
      console.log("Clipboard API is not supported")
        // Clipboard API is not supported
    }
    // const path = "https://t.me/yostory_bot"; 
    // navigate(path);
  };
  
  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
            // Display the toast notification
            setShowToast(true);
            // Hide the toast notification after 5 seconds
            setTimeout(() => setShowToast(false), 5000);
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
    } else {
      alert(textToCopy)
        // Clipboard API is not supported
    }
  };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
          <Toast show={showToast} onClose={() => setShowToast(false)}>
              <Toast.Header>
                  <strong className="me-auto">Уведомление</strong>
                  <small>Только что</small>
              </Toast.Header>
              <Toast.Body>Текст скопирован!</Toast.Body>
          </Toast>
      </ToastContainer>
      <Stack gap={4} className="col-md-5 mx-auto">
        <center>
          <TopHeader>Реферальная программа</TopHeader>
        </center>
        <center>
          <ParagraphTextShort>
          Чтобы вывести деньги, отправьте по кнопке 🛎 <strong>Помощь в Боте</strong> следующее сообщение:
          </ParagraphTextShort>
        </center>
        <center>
          <ButtonGradient onClick={copyToClipboard} style={{ position: 'relative' }}>
            <div
              style={{
                paddingLeft: '3em',
                paddingRight: '3em',
              }}>
                {textToCopy}
              </div>
            <Copy 
              size={'1.5em'}
              style={{ 
                  position: 'absolute', 
                  top: '1em', // Adjust this value as needed
                  right: '1em' // Adjust this value as needed
              }} 
            />
          </ButtonGradient>
        </center>
        <center>
          <img src={SupportHelpImage} alt="Кнопка 🛎 Помощь в боте" style={{maxWidth: "100%"}} />
        </center>
      </Stack>
    </>
  );
}

export default Support;