import axios from 'axios';

const urlApi = `${process.env.REACT_APP_API_HOST_IP}`
const usernameBasicAuth = `${process.env.REACT_APP_USERNAME_BASIC_AUTH}`
const passwordBasicAuth = `${process.env.REACT_APP_PASSWORD_BASIC_AUTH}`

const urlApiEndpointUsers = urlApi + '/api/v1/users/'
const urlApiEndpointPaywallSubscriptionPlan = urlApi + '/api/v1/paywall_subscription_plan/'
const urlApiEndpointSubscriptionPlan = urlApi + '/api/v1/subscription_plan/'
const urlApiEndpointReferralPlan = urlApi + '/api/v1/referral_plan/'
const urlApiEndpointPromoCode = urlApi + '/api/v1/promo_code/'

export const crudGetSubscriptionPlanByTelegramId = async (telegramId) => {
  try {
    const auth = btoa(`${usernameBasicAuth}:${passwordBasicAuth}`);
    const urlTarget = urlApiEndpointSubscriptionPlan + 'tg/' + telegramId;
    
    const response = await axios.get(urlTarget, {
      headers: {
        'Authorization': `Basic ${auth}`,
      }
    });

    return response.data.subscription_plan;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const crudApplyPromoCodeByTelegramId = async (promoCode, telegramId) => {
  try {
    const auth = btoa(`${usernameBasicAuth}:${passwordBasicAuth}`);
    const urlTarget = urlApiEndpointPromoCode + 'code/' + promoCode + '/apply/' + telegramId;
    
    const response = await axios.post(urlTarget, {}, {
      headers: {
        'Authorization': `Basic ${auth}`,
      }
    });

    return response.data.promo_code;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const crudGetReferralPlanByTelegramId = async (telegramId) => {
  try {
    const auth = btoa(`${usernameBasicAuth}:${passwordBasicAuth}`);
    const urlTarget = urlApiEndpointReferralPlan + 'tg/' + telegramId;
    
    const response = await axios.get(urlTarget, {
      headers: {
        'Authorization': `Basic ${auth}`,
      }
    });

    return response.data.referral_plan;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const crudGetReferralPlanByReferralCode = async (referralCode) => {
  try {
    const auth = btoa(`${usernameBasicAuth}:${passwordBasicAuth}`);
    const urlTarget = urlApiEndpointReferralPlan + 'code/' + referralCode;
    
    const response = await axios.get(urlTarget, {
      headers: {
        'Authorization': `Basic ${auth}`,
      }
    });

    return response.data.referral_plan;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const crudPostReferralPlan = async (data) => {
  try {
    const auth = btoa(`${usernameBasicAuth}:${passwordBasicAuth}`);
    const urlTarget = urlApiEndpointReferralPlan;
    
    const response = await axios.post(urlTarget, data, {
      headers: {
        'Authorization': `Basic ${auth}`,
      }
    });

    return response.data.referral_plan;
  } catch (error) {
    console.error(error);
    return {};
  }
};