import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import { ButtonGradient } from '../../elements/Buttons';
import { Link } from 'react-router-dom';
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

import { crudGetSubscriptionPlanByTelegramId } from '../../actions/interactions';
import { AccountCard } from '../../elements/AccountCard';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`


const Account = ({userData}) => {
  const [subscriptionPlanData, setSubscriptionPlanData] = useState({});
  const [show, setShow] = useState(false);

  const userTelegramId = userData.id
  const userUsername = userData.username

  const dummySubscriptionPlan = {
    name: 'Тестовая подписка',
    creative_calls_allowed: 30,
    expires_on: '21.12.2024',
    renewal_price: '10'
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    crudGetSubscriptionPlanByTelegramId(userTelegramId).then(crudSubscriptionPlanData => {
      setSubscriptionPlanData(crudSubscriptionPlanData);
    });
  });

  return (
    <>
      {/* <Stack gap={2} className="col-md-5 mx-auto justify-content-between" style={{ height: '60vh', display: 'flex', direction: 'column', justifyContent: 'space-between'}}> */}
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <ParagraphText>
            Отменить подписку
          </ParagraphText></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ParagraphText>Вы хотите отменить вашу подписку</ParagraphText>
        {/* <ParagraphText><strong>«{modalName}»</strong></ParagraphText> */}
        <ButtonGradient variant="primary">Подтвердить</ButtonGradient></Modal.Body>
      </Modal>
      <Stack gap={2} className="col-md-5 mx-auto">
        <center>
          <TopHeader>Мой аккаунт</TopHeader>
        </center>
        {subscriptionPlanData ? <AccountCard subscriptionPlan={subscriptionPlanData} handleShow={handleShow}/> 
        : 
          <div style={{ height: '30vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <SectionHeader>Добро пожаловать, @<strong>{userUsername}</strong>. У Вас пока нет подписки</SectionHeader>
            <ButtonGradient as={Link} to="/subscription" variant="primary" >Оформить подписку</ButtonGradient>
          </div>
        }
      </Stack>
      {/* <Button variant="primary" type="submit">
        Удалить аккаунт
      </Button> */}
    </>
  );
}

export default Account;