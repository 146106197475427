import * as React from 'react';
import styled from "styled-components";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';

import { Link } from 'react-router-dom';

import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';
import { ButtonSubscriptionChoice, ButtonOutlineSubscriptionChoice } from '../Buttons';

const AccountCardContainer = styled(Card)`
  color: #ffffff;
  border-radius: 1em;
  padding: 0.5em;
  border: 0px;
  background: linear-gradient(
    211.56deg,
    rgba(70, 196, 232, 1) 0.4416228737682104%,
    rgba(203, 75, 227, 1) 100%
  );
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
`
const SubscriptionOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const SubscriptionDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
`

const AccountInfoCategory = styled.div`
  font-size: 1em;
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
`

const SubscriptionNumber = styled.div`
  font-size: 1em;
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
`
const SubscriptionPrice = styled.div`
  display: flex;
  flex-direction: column;
`

const SubscriptionPriceFocus = styled.div`
  color: #9D75E5;
`

const SubscriptionPriceOutOfFocus = styled.div`
  color: #BDBBBB;
  font-size: 0.8em;
  text-decoration: line-through;
`
const formatDateToDDMMYYYY = ( date ) => {
  if (date) {
    const day = String(date.getDate()).padStart(2, '0'); // Gets the day and pads with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Gets the month (0-based) and pads with zero
    const year = date.getFullYear(); // Gets the full year
    return `${day}.${month}.${year}`; // Formats as DD.MM.YYYY
  } else {
    return '-'
  }
}

export const AccountCard = ({ subscriptionPlan, handleShow }) => {

    const expirationDate = (subscriptionPlan && subscriptionPlan.expires_on) ? new Date(subscriptionPlan.expires_on) : null
    // const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    // const formattedExpirationDate = expirationDate ? new Intl.DateTimeFormat('ru-RU', dateOptions).format(expirationDate) : null;
    const formattedExpirationDate = formatDateToDDMMYYYY(expirationDate);
    
    return (
      <AccountCardContainer>
        <Card.Body>  
          <TopHeader>
            {/* {subscriptionPlan.name} */}
            Ваша подписка
          </TopHeader>
          <Card.Text>
            <div>
              <SubscriptionDescription>
                <AccountInfoCategory>Осталось сказок</AccountInfoCategory>
                <SubscriptionNumber>{subscriptionPlan.creative_calls_allowed - subscriptionPlan.creative_calls_used}/{subscriptionPlan.creative_calls_allowed}</SubscriptionNumber>
              </SubscriptionDescription>
              {/* <SubscriptionDescription>
                <AccountInfoCategory>Срок подписки</AccountInfoCategory>
                <SubscriptionNumber>1 месяц</SubscriptionNumber>
              </SubscriptionDescription> */}
              <SubscriptionDescription>
                <AccountInfoCategory>{subscriptionPlan.renewals_allowed ? "Продление подписки" : "Истечение подписки"}</AccountInfoCategory>
                <SubscriptionNumber>{formattedExpirationDate}</SubscriptionNumber>
              </SubscriptionDescription>
              {subscriptionPlan.renewals_allowed ? <SubscriptionDescription>
                <AccountInfoCategory>Сумма к списанию</AccountInfoCategory>
                <SubscriptionNumber>{subscriptionPlan.renewal_price}₽</SubscriptionNumber>
              </SubscriptionDescription> : null}
              <SubscriptionDescription>
                <AccountInfoCategory>Дополнительные сказки</AccountInfoCategory>
                <SubscriptionNumber>{subscriptionPlan.creative_calls_bonus_allowed}</SubscriptionNumber>
              </SubscriptionDescription>
            </div>
          </Card.Text>
          <Stack gap={2}>
            <ButtonSubscriptionChoice as={Link} to="/subscription" variant="primary">
              <SubscriptionOptions>
                <div>Улучшить подписку</div>
              </SubscriptionOptions>
            </ButtonSubscriptionChoice>
            <ButtonSubscriptionChoice as={Link} to="/purchaseBonus" variant="primary">
              <SubscriptionOptions>
                <div>Дополнительные сказки</div>
              </SubscriptionOptions>
            </ButtonSubscriptionChoice>
            <ButtonOutlineSubscriptionChoice variant="primary" onClick={handleShow}>
              <SubscriptionOptions>
                <div>Отменить подписку</div>
              </SubscriptionOptions>
            </ButtonOutlineSubscriptionChoice>
          </Stack>
        </Card.Body>
      </AccountCardContainer>
    );
  };