import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Toast, ToastContainer } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { SubscriptionCard, dummySubscriptionPlans, dummySubscriptionPlansBlocked } from '../../elements/SubscriptionCard';
import { ButtonGradient, ButtonSmallGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

import { handlePurchaseBonusAction } from '../../actions/payment';
import { crudGetSubscriptionPlanByTelegramId, crudApplyPromoCodeByTelegramId } from '../../actions/interactions';

const PaymentLink = styled.a`
  color: #ffffff;
  font-family: "MontserratAlternates";
  font-weight: bold;
  outline: none;
  text-decoration: none;
  -moz-outline-style: none; 

  :active, :focus { outline: none; -moz-outline-style: none; }

`

const PurchaseOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const PurchaseDescription = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
`

const PurchaseNumber = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
`
const PurchasePrice = styled.div`
  display: flex;
  flex-direction: column;
`

const bonusPack = {
  name: 'Пакет сказок',
  options: [
    {
      name: 'Пакет 5 сказок',
      bonusNumber: 5,
      priceFocus: '99',
    },
    {
      name: 'Пакет 10 сказок',
      bonusNumber: 10,
      priceFocus: '199',
    },
    {
      name: 'Пакет 20 сказок',
      bonusNumber: 20,
      priceFocus: '399',
    }
  ]
}

const PurchaseBonus = ({userData}) => {

  const [show, setShow] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [modalLink, setModalLink] = useState(false);
  const [modalName, setModalName] = useState();
  const [modalDuration, setModalDuration] = useState();
  const [email, setEmail] = useState();
  const [promoCode, setPromoCode] = useState();
  const [promoCodeToUse, setPromoCodeToUse] = useState();
  const [subscriptionPlanData, setSubscriptionPlanData] = useState({});
  const [showToast, setShowToast] = useState(false); // State to manage toast visibility
  
  const userTelegramId = userData.id
  const userUsername = userData.username
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOpenPageByModalLink = () => window.open(modalLink, "_blank");
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };
  const handlePromoCodeChange = (event) => {
    const newPromoCode = event.target.value;
    setPromoCode(newPromoCode);
  };
  const handlePreventEnter = (event) => {
    if (['Enter', '13', 13].includes(event.target.key)) {
      event.preventDefault();
    }
  };
  const handlePreventDefault = (event) => {
    event.preventDefault();
    if (document.activeElement) {
      document.activeElement.blur();
    };
  };
  const handleApplyPromoCode = (event) => {
    event.preventDefault();
    crudApplyPromoCodeByTelegramId(promoCode, userTelegramId).then(resultPromoCodeData => {
      if (resultPromoCodeData.code) {
        setPromoCodeToUse(resultPromoCodeData.code);
      } else {
        setPromoCodeToUse(null);
      }
      setShowToast(true);
      // setPromoCode('');
    }).catch(error => {
      console.log(error)
      setPromoCodeToUse(null);
      setShowToast(true);
      // setPromoCode('');
    })
  }
  useEffect(() => {
    crudGetSubscriptionPlanByTelegramId(userTelegramId).then(crudSubscriptionPlanData => {
      if (crudSubscriptionPlanData && crudSubscriptionPlanData.email && !email) {
        setEmail(crudSubscriptionPlanData.email);
      };
      setSubscriptionPlanData(crudSubscriptionPlanData);
    });
  }, []);

  return (
    <>
    <ToastContainer position="top-end" className="p-3">
      <Toast show={showToast} onClose={() => setShowToast(false)}>
          <Toast.Header>
              <strong className="me-auto">Уведомление</strong>
              <small>Только что</small>
          </Toast.Header>
          {promoCodeToUse ? <Toast.Body>Промо-код {promoCodeToUse} принят!</Toast.Body> : <Toast.Body>Промо-код не может быть принят!</Toast.Body>}
      </Toast>
    </ToastContainer>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <ParagraphText>
            Оплата заказа
          </ParagraphText></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ParagraphText>Вы хотите докупить сказок</ParagraphText>
        <ParagraphText><strong>«{modalName}»</strong></ParagraphText>
        <PaymentLink href={modalLink}><ButtonGradient variant="primary">Перейти на страницу оплаты</ButtonGradient></PaymentLink></Modal.Body>
      </Modal>
    <Stack gap={2} className="col-md-5 mx-auto">
      <center>
        <TopHeader>Дополнительные сказки</TopHeader>
      </center>
      <Form onSubmit={handlePreventDefault}>
        <Form.Group className="mb-3" controlId="email-form.ControlInput1">
          <Form.Label>Введите ваш email</Form.Label>
          <Form.Control type="email" placeholder="name@example.com" required autocomplete="on" value={email} onChange={handleEmailChange} />
          <Form.Control.Feedback type="invalid">
            Ввод почтового адреса обязателен для оформления покупки
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
      <Form onSubmit={handleApplyPromoCode}>
        <Row className="align-items-center">
            <Col xs="auto">
              <Form.Label htmlFor="form-input-promo-code" >
                Введите промокод
              </Form.Label>
              <Form.Control
                className="mb-2"
                id="form-input-promo-code"
                placeholder="Нет кода"
                onChange={handlePromoCodeChange}
              />
            </Col>
            <Col xs="auto">
              <ButtonSmallGradient type="submit" disabled={!promoCode} className="mb-2">
                Принять
              </ButtonSmallGradient>
            </Col>
          </Row>
      </Form>
      {bonusPack.options.map((bonusPackOption) => (
        <ButtonGradient disabled={!email || isInProgress} onClick={async () => { 
          setIsInProgress(true);
          const modalLink = await handlePurchaseBonusAction(userData, email, bonusPackOption, setModalLink)
            .then(function (result) { 
              setIsInProgress(false);
              return result})
            .catch(function (error) { 
              setIsInProgress(false);
              console.log(error);
              return null
            })
          // console.log(modalLink)
          setModalName(bonusPackOption.name);
          setModalDuration(bonusPackOption.name)
          // setModalLink(modalLink);
          handleShow();
          }}>
          <PurchaseOptions>
            <PurchaseDescription>
              {bonusPackOption.name}
            </PurchaseDescription>
            <PurchaseNumber>
              <PurchasePrice>
                {bonusPackOption.priceFocus}₽
              </PurchasePrice>
            </PurchaseNumber>
          </PurchaseOptions>
        </ButtonGradient>
      ))}
    </Stack>
    </>
  );
}

export default PurchaseBonus;